import React, { Component } from 'react';
import { GoogleMap, Marker, LoadScript, Circle } from '@react-google-maps/api';
import axios from 'axios';
import { getGoogleMapKey } from './service';

const mapContainerStyle = {
  width: '100%',
  height: '500px',
};
export default class GoogleMapComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLocation: { lat: 0, lng: 0 },
      center: { lat: 40.7128, lng: -74.0060 },
      zoom: 14,
      isLoaded: false,
      loadError: null,
      googleMapKey : "",
    };
    this.autocompleteRef = React.createRef();

    this.handleMapClick = this.handleMapClick.bind(this);
    this.handleZoomChanged = this.handleZoomChanged.bind(this);
    this.handleLoadScript = this.handleLoadScript.bind(this);
    this.handleLoadError = this.handleLoadError.bind(this);
  }
  componentDidMount() {
    getGoogleMapKey().then(res => {
      if (res.status === "OK"){
        this.setState({
          googleMapKey : res.data,
        })
      }
    });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          this.setState({
            center: { lat: latitude, lng: longitude },
          });
        },
        (error) => {
          console.error("Error fetching user's location:", error);
        },
        { enableHighAccuracy: true }
      );
    } else {
      console.warn('Geolocation is not supported by this browser.');
    }
  }

  handleLoadScript() {
    this.setState({ isLoaded: true });
  }
  handleLoadError(error) {
    this.setState({ loadError: error });
  }

  fetchLocationName(lat, lng) {
    const apiKey = this.state.googleMapKey;
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

    axios.get(geocodeUrl)
      .then(response => {
        if (response.data.status === 'OK') {
          const address = response.data.results[0]?.formatted_address || 'Unknown location';
          this.setState({ locationName: address });
          this.props.setFieldValue('locationName', address); // Update Formik field
        }
      })
      .catch(error => {
        console.error("Error fetching location name:", error);
      });
  }
  handleMapClick(event) {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    this.setState({ selectedLocation: { lat, lng } });
    this.props.setFieldValue('latitude', lat);
    this.props.setFieldValue('longitude', lng);
    this.fetchLocationName(lat, lng);
  }

  handleZoomChanged() {
    const map = this.map;
    if (map) {
      this.setState({ zoom: map.getZoom() });
    }
  }

  render() {
    const { selectedLocation, zoom, center, locationName, googleMapKey, loadError, isLoaded } = this.state;
    const { latitude, longitude ,radius} = this.props;
    
    if (loadError) return <div>Error loading maps: {loadError.message}</div>;
    if (!googleMapKey) { return <div>Loading Maps...</div>; }

    return (
      <LoadScript
        googleMapsApiKey={googleMapKey}
        libraries={['places']}
        onLoad={this.handleLoadScript}
        onError={this.handleLoadError}
      >
        <div>{locationName &&
          <h4>Location: {locationName}</h4>}
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={zoom} center={center}
            onClick={this.handleMapClick}
            onZoomChanged={this.handleZoomChanged}
            onLoad={(map) => {
              this.map = map;
              if (latitude && longitude) {
                const latLng = { lat: parseFloat(latitude), lng: parseFloat(longitude) };
                this.setState({ selectedLocation: latLng });
                this.map.panTo(latLng);
              }
            }}
          >
            {(selectedLocation.lat !== 0 || (latitude && longitude)) && (
              <><Marker position={selectedLocation.lat !== 0 ? selectedLocation : { lat: parseFloat(latitude), lng: parseFloat(longitude) }} />
                <Circle center={ selectedLocation.lat !== 0 ? selectedLocation : { lat: parseFloat(latitude), lng: parseFloat(longitude) }}
                  radius={radius}
                  options={{ strokeColor: "#0000FF",
                    strokeOpacity: 0.8, strokeWeight: 2,
                    fillColor: "#0000FF", fillOpacity: 0.35,
                  }}
                /></>
            )}
          </GoogleMap>
        </div>
      </LoadScript>
    );
  }
}
