import React, { Component } from 'react';
import AttendanceConfiguration from './attendanceConfig';
import { verifyOrgLevelViewPermission } from '../../utility';
import AccessDenied from '../../MainPage/Main/Dashboard/AccessDenied';
import LocationConfig from './Locations';
import IPConfig from './IPs';
export default class ConfigurationRoute extends Component {
   render() {
      return (
         <div style={{ backgroundColor: '#f5f5f5' }} className="page-wrapper">
            <div className="content container-fluid">
               <div className="mt-4 tab-content">
                  <div className="subMenu_box row user-tabs">
                     <div className="nav-box">
                        <div className="page-headerTab">
                           <h3 style={{ color: 'white' }} className="page-title">Configuration Setup</h3>
                           <div className="p-0 col-lg-12 col-md-12 col-sm-12 sub-nav-tabs">
                              <ul className="nav nav-items">
                              <li className="nav-item"><a href="#attendance" data-toggle="tab" className="nav-link active">Attendance</a></li>
                              <li className="nav-item"><a href="#location" data-toggle="tab" className="nav-link">Location </a></li>
                              <li className="nav-item"><a href="#ip" data-toggle="tab" className="nav-link">IP </a></li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div id="attendance" className="pro-overview tab-pane fade show active">
                    {verifyOrgLevelViewPermission("Settings Configuration") && 
                     <AttendanceConfiguration></AttendanceConfiguration>}
                     {(!verifyOrgLevelViewPermission("Settings Configuration"))&& <AccessDenied></AccessDenied>}
                  </div>
                  <div id="location" className="pro-overview tab-pane fade ">
                    {verifyOrgLevelViewPermission("Settings Configuration") && 
                     <LocationConfig></LocationConfig>}
                     {(!verifyOrgLevelViewPermission("Settings Configuration"))&& <AccessDenied></AccessDenied>}
                  </div>
                  <div id="ip" className="pro-overview tab-pane fade ">
                    {verifyOrgLevelViewPermission("Settings Configuration") && 
                     <IPConfig></IPConfig>}
                     {(!verifyOrgLevelViewPermission("Settings Configuration"))&& <AccessDenied></AccessDenied>}
                  </div>
               </div>
            </div>
         </div>
      )
   }
}