
import React, { Component } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Helmet } from "react-helmet";
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { loginLogo, loginbg } from '../Entryfile/imagepath.jsx';
import { forgotPasswordReset, validateToken } from './service.jsx';
import PasswordField from './PasswordField.jsx';
import { FormGroup } from 'react-bootstrap';
import { setUserData } from '../utility.jsx';

toast.configure();
class ForgotPasswordReset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: new URLSearchParams(props.location.search).get('token'),
      isTokenValidated: false,
      isTokenValid: false,
      invalidTokenMessage: "",
      showPassword: false,
    }
  }
  componentDidMount() {
    const { token } = this.state;
    validateToken(token).then(res => {
      console.log({ res });
      if (res.status == "OK") {
        setUserData({
          companySetting: res.data,
        });
        this.setState({
          isTokenValidated: true,
          isTokenValid: true
        })
      } else {
        this.setState({
          isTokenValidated: true,
          isTokenValid: false,
          invalidTokenMessage: res.message
        })
      }
    })
  }
  postForgotPasswordReset = (data, action) => {
    forgotPasswordReset({
      email: data.email,
      password: data.password,
      token: data.token
    }).then(res => {
      if (res.status == "OK") {
        toast.success(res.message);
        setTimeout(() => {
          window.location.href = "/login";
        }, 500);
      } else {
        toast.error(res.message);
      }
    })
  }

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  render() {
    const { token, isTokenValid, isTokenValidated, invalidTokenMessage, showPassword } = this.state;
    return (
      <div className="loginPage main-wrapper container-fluid">
        <Helmet>
        <title>Reset Password - WorkPlus</title>
          <meta name="description" content="Login page" />
        </Helmet>
        <div className="row h-100">
          <div className="col-lg-7 d-none d-sm-flex login_img" style={{
            backgroundImage: `url(${loginbg})`
          }}>
            <div>

              <h2>The <span> employee experience platform </span> for <br />
                 empowering a digital and eco-friendly workspace</h2>
              {/* <img className="img-fluid" src={loginbg} alt="WorkPlus" /> */}
            </div>
          </div>
          <div className="col-lg-5 login_form">
            <div style={{ marginTop: '90px' }} className="account-logo">
              <h3><p><img src={loginLogo} alt="WorkPlus" /></p></h3>
              <div className="account-box">
                <div className="account-wrapper">
                  <h3 className="account-title">OTP</h3>
                  {/* Account Form */}
                  <Formik
                  enableReinitialize={true}
                  initialValues={{
                    password: '',
                    token: new URLSearchParams(this.props.location.search).get('token'),
                    email:'',
                    confirmPassword:''
                  }}
                  onSubmit={this.postForgotPasswordReset}
                  validationSchema={Yup.object().shape({
                    email: Yup.string()
                      .email("Please enter valid Email")
                      .required("Please provide Email"),
                    password: Yup.string()
                      .required('Please provide password'),
                    confirmPassword: Yup.string()
                      .required('Please provide password')
                      .oneOf([Yup.ref('password'), null], 'Passwords must match')
                  })}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    setSubmitting
                    /* and other goodies */
                  }) => (
                    <Form>
                      <FormGroup>
                        
                        <Field name="email" className="form-control" required placeholder="Enter Email*" />

                        <ErrorMessage name="email">
                          {msg => <div style={{ color: 'red' }}>{msg}</div>}
                        </ErrorMessage>
                      </FormGroup>
                      <FormGroup>
                       
                       <PasswordField placeholder="Password *" name="password" required  onChange={(value) => {
                          setFieldValue("password", value);
                        }} />
                        <ErrorMessage name="password">
                          {msg => <div style={{ color: 'red' }}>{msg}</div>}
                        </ErrorMessage>
                      </FormGroup>

                      <FormGroup>
                        <div className="pswrdField">
                          <span className="form-element">
                          <span onClick={this.togglePasswordVisibility} className={showPassword ? `fa fa-eye-slash` : `fa fa-eye`}></span>
                            <Field
                              name="confirmPassword"
                              type={showPassword ? 'text' : 'password'}
                              required
                              className="form-control"
                              placeholder="Confirm Password *" />
                            
                          </span>
                        </div>
                        <ErrorMessage name="confirmPassword">
                          {msg => <div style={{ color: 'red' }}>{msg}</div>}
                        </ErrorMessage>
                      </FormGroup>
                      <div className="text-center form-group">
                        <input type="hidden" name="token" value={values.token} />
                        <input type="submit" className="btn btn-primary account-btn" value="Reset Password" />
                      </div>
                    </Form>)}
                </Formik>
                </div>

              </div>
            </div>
            {isTokenValidated && !isTokenValid && <div className='text text-danger'>
            <strong>{invalidTokenMessage}</strong>
          </div>}
          </div>

        </div>
      </div>
      
    );
  }
}


export default ForgotPasswordReset;
